import React, { useEffect } from "react";
import "./register.css";

const REG_URL =
  "https://forms.gle/wev1Bx2UBfv5XYZb7";

function RegisterPage() {
  useEffect(() => {
    window.location = REG_URL;
  }, []);

  return (
    <div id="register" className="text-center">
      <h2 className="loading my-5">Cargando...</h2>
    </div>
  );
}

export default RegisterPage;
