import React from "react";
import "./header.css";
import { Link } from "react-router-dom";

const hideRegistro = process.env.REACT_APP_HIDE_REGISTRO;

function HeaderSection() {
  return (
    <div id="header">
      <div className="container d-flex flex-wrap justify-content-center">
        <Link className="item" to="/">
          Inicio
        </Link>
        <a
          className="item d-none d-md-block"
          href={`${process.env.PUBLIC_URL}/#ponentes`}
        >
          Ponentes
        </a>
        <a className="item" href={`${process.env.PUBLIC_URL}/#conferences`}>
          Conferencias
        </a>
        <a
          className="item d-none d-md-block"
          href={`${process.env.PUBLIC_URL}/#patrocinadores`}
        >
          Patrocinadores
        </a>
        <Link className="item" to="/calendario">
          Calendario de actividades
        </Link>
        <Link className="item" to="/Videos">
          Videos de los Congresos
        </Link>
          <Link to="/YTChannel">
            <button className="btn btn-primary">¡Visita Nuestro Canal de YouTube!</button>
          </Link>
        {hideRegistro ? null : (
          <Link to="/registro">
            <button className="btn btn-secondary">¡Regístrate ya!</button>
          </Link>
        )}
      </div>
    </div>
  );
}

export default HeaderSection;
