import React, { useEffect, useState } from "react";
import "./calendar.css";
import arrow from "../../assets/img/Arrow.png";
import F1 from "../../assets/img/Fechas1.png";
import F2 from "../../assets/img/Fechas2.png";
import F3 from "../../assets/img/Fechas3.png";
import DayCard from "./DayCard";
import { getConferencesWithDays } from "../../services/conferencesService";

function CalendarPage() {
  const [conferences, setConferences] = useState([]);

  const fetchConferences = async () =>
    setConferences(await getConferencesWithDays());

  useEffect(() => fetchConferences(), []);
  return (
    <div id="calendar">
      <div className="title-p row">
        <div className="title col-md-8 col-sm-12 text-center text-md-start">
          Calendario de actividades 2024
        </div>
        <div className="col-md-4 col-12 text-center text-md-end my-4 my-md-0">
          <a
            href="https://congreso-ceti-colomos.com/static/img/Calendario.pdf"
            target="_blank"
            rel="noreferrer"
            className="btn btn-primary"
          >
            <img src={arrow} alt="" /> Descargar como PDF
          </a>
        </div>
      </div>
      <div className="content col-md-8">
        <div className="content-days row subtitle">
          <div className="col-md-4 m-tw-c">
            <label>
              Miércoles <b>13</b> de Noviembre
            </label>
            <img src={F1} alt=""/>
            {conferences.quince?.map((conference) => (
              <DayCard key={conference.id} conference={conference} />
            ))}
          </div>
          <div>
            <label>
              Jueves <b>14</b> de Noviembre
            </label>
            <img src={F2} alt=""/>
            {conferences.dieciseis?.map((conference) => (
              <DayCard key={conference.id} conference={conference} />
            ))}
          </div>
          <div className="col-md-4 m-tw-c">
            <label>
              Viernes <b>15</b> de Noviembre
            </label>
            <img src={F3} alt=""/>
            {conferences.Diecisiete?.map((conference) => (
              <DayCard key={conference.id} conference={conference} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalendarPage;
