import React, { useEffect } from "react";
import "./ytchannel.css";

const Channel_URL =
  "https://www.youtube.com/@CongresoVirtualCETI";

function YTChannel() {
  useEffect(() => {
    window.location = Channel_URL;
  }, []);

  return (
    <div id="Channel" className="text-center">
      <h2 className="loading my-5">Redirigiendo al Canal de YouTube...</h2>
    </div>
  );
}

export default YTChannel;
