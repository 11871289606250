import React, { useState } from 'react';
import './videos.css';

const galleryNames = {
  Congreso1: '1er Congreso',
  Congreso2: '2do Congreso',
  Congreso3: '3er Congreso',
  Congreso4: '4to Congreso',
  // Futuro CETI: Aqui puedes agregar mas nombres de congresos estos son los nombres amigables con el usuario
};

const videosData = {
  Congreso1: [
    { id: 'g5EdnSs2K-o', title: 'DIA 1<br />MODELOS DE APRENDIZAJE<br />EMPRENDIMIENTO<br />BIOIMPEDANCIA' },
    { id: 'OImQtfMD5QM', title: 'DIA 2<br />CAMBIO CLIMATICO EN LAS CIUDADES<br />EFECTO DE SITIO<br />FLUIDOS SUPERCRITICOS' },
    { id: 'tcHzBcSfPzg', title: 'DIA 3<br />LA IMPORTANCIA DE DOCUMENTAR EL PROCEDIMIENTO<br />TOMOGRAFIA: DEL CEREBRO AL FOTÓN<br />MIGRACION AL SISTEMA DE VIRTUALIZACION POR HIPERCONVERGENCIA' },
    { id: 'bUe6QqLjwZo', title: 'DIA 4<br />APLICACION DE LA INTELIGENCIA ARTIFICIAL EN LA INGENIERÍA<br />ALGORITMOS METAHEURÍSTICOS CON INSPIRACION BIOLÓGICA<br />NANOTECNOLOGÍA' },
    { id: 'ZD7zDg2zKv0', title: 'DIA 5<br />MÉTODOS DE SECADO PARA LA CONSERVACIÓN DE ALIMENTOS<br />SIMULACION MOLECULAR DE CRISTALES LÍQUIDOS LIOTRÓPICOS<br />CIENCIA CONCIENCIA' }
  ],
  Congreso2: [
    {id: 'ehSMWTOe3uA', title: 'DIA 1 <br /> PROGRAMACIÓN FUNCIONAL <br /> MATERIA OSCURA Y RECONOCIMIENTO DE PATRONES'},
    {id: 'Nw0Lr3OIujo', title: 'DIA 2 <br /> AUTOENCODERS<br /> MATEMÁTICAS CLÁSICAS Y EMPRENDIMIENTO'},
    {id: '8P1tmjm2rnw', title: 'DIA 3 <br /> INGENIERÍA MODERNA <br />AUTOMATIZACIÓN Y CIUDADES INTELIGENTES'},
    {id: 'b0usdTFidRI', title: 'DIA 4<br />MATERIALES CUÁNTICOS<br />CONSTRUCCIÓN SOSTENIBLE Y LENGUAJE CORPORAL'},
    {id: 'Uz1Xm21l2AA', title: 'DIA 5<br />SABORES INDUSTRIALES<br />INACTIVACIÓN FOTODINÁMICA Y COMUNICACIÓN PERSUASIVA'},
  ],
  Congreso3: [
    {id: 'UdtJbTzcX0Q', title: 'DIA 1<br />PROGRESIÓN CREATIVA EN EL CAMPO PROFESIONAL<br />ROBOTICA<br />NEUROLIDERAZGO'},
    {id: '14jEWpaA-Lo', title: 'DIA 2<br />CIENCIA DE DATOS<br />REALIDAD VIRTUAL<br />VIDEOJUEGOS'},
    {id: '0reDOm9TTEM', title: 'DIA 3<br />SOFT SKILLS EN LA INGENIERÍA'},
    {id: 'MRI64OKBI', title: 'DIA 4<br />SUSTENTABILIDAD Y MOVILIDAD<br />SOFTWARE EN EL MUNDO REAL<br />METACOGNICIÓN<br />TELESALUD'},
],
Congreso4: [
  {id: 'U5omd8fs5EY', title: 'DIA 1<br />DISEÑO DE VIDEOJUEGOS: FALTA DE INGENIEROS PARA LLEVAR EL CONCEPTO A LA REALIDAD'},
  {id: 'KR2TuRFEATI', title: 'DIA 2<br />MITOS DE LA PROGRAMACIÓN<br />RETOS VIRTUALES<br />SOFTSKILLS PARA INGENIEROS'},
  {id: 'NmsAemCXBx8', title: 'DIA 3<br />EDUCACIÓN SUPERIOR<br />IA EN PROGRAMACIÓN<br />FULLFILMENT EN INGENIERÍA'},
],
};

const Videos = () => {
  const [selectedGallery, setSelectedGallery] = useState('Congreso1');

  const handleGalleryChange = (event) => {
    setSelectedGallery(event.target.value);
  };

  return (
    <div className="videos-page">
      <h1>Videos</h1>
      <label htmlFor="gallerySelect">Elije un congreso:</label>
      <select id="gallerySelect" onChange={handleGalleryChange} value={selectedGallery}>
        {Object.keys(videosData).map(gallery => (
          <option key={gallery} value={gallery}>
            {galleryNames[gallery]} 
          </option>
        ))}
      </select>

      <div>
        {videosData[selectedGallery].map(video => (
          <div key={video.id} className="video-container">
            <h2 dangerouslySetInnerHTML={{ __html: video.title }}></h2>
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${video.id}`}
              title={video.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Videos;


